import React from "react";
import CtaBanner from "../../components/cta-banner";
import Layout from "../../components/layout";
import ServicesHero from "../../components/services/hero";
import PhotoLeft from "../../components/services/photo-left";
import PhotoRight from "../../components/services/photo-right";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { useStaticQuery, graphql } from "gatsby";
import Faq from "../../components/services/faq";
import { GatsbySeo } from "gatsby-plugin-next-seo";
import { Helmet } from "react-helmet";

const BusinessCourse = () => {
	const data = useStaticQuery(graphql`
		query {
			logoImg: file(relativePath: { eq: "logo.png" }) {
				publicURL
				childImageSharp {
					gatsbyImageData(
						quality: 100
						placeholder: BLURRED
						formats: [AUTO, WEBP, AVIF]
					)
				}
			}
			heroImg: file(
				relativePath: {
					eq: "services/business-of-photography-course/BC-image-hero.jpg"
				}
			) {
				publicURL
				childImageSharp {
					gatsbyImageData(
						quality: 100
						placeholder: BLURRED
						formats: [AUTO, WEBP]
					)
				}
			}
			bcImage1: file(
				relativePath: {
					eq: "services/business-of-photography-course/BC-image1.jpg"
				}
			) {
				childImageSharp {
					gatsbyImageData(
						quality: 100
						placeholder: BLURRED
						formats: [AUTO, WEBP]
					)
				}
			}
			bcImage2: file(
				relativePath: {
					eq: "services/business-of-photography-course/BC-image2.jpg"
				}
			) {
				childImageSharp {
					gatsbyImageData(
						quality: 100
						placeholder: BLURRED
						formats: [AUTO, WEBP]
					)
				}
			}
			bcImage3: file(
				relativePath: {
					eq: "services/business-of-photography-course/BC-image3.jpg"
				}
			) {
				childImageSharp {
					gatsbyImageData(
						quality: 100
						placeholder: BLURRED
						formats: [AUTO, WEBP]
					)
				}
			}
			bcImage4: file(
				relativePath: {
					eq: "services/business-of-photography-course/BC-image4.jpg"
				}
			) {
				childImageSharp {
					gatsbyImageData(
						quality: 100
						placeholder: BLURRED
						formats: [AUTO, WEBP]
					)
				}
			}

			site {
				siteMetadata {
					siteUrl
					company
				}
			}
		}
	`);

	const siteUrl = data.site.siteMetadata.siteUrl;
	const pageUrl = "/services/business-of-photography-course";
	const companyName = data.site.siteMetadata.company;
	const schemaImg = data.heroImg.publicURL;
	const logoImg = data.logoImg.publicURL;

	const faqs = [
		{
			question: "Module 1: Your Business & Brand",
			answer:
				"You'll create your killer brand, impacting what people see, hear and feel about your business, and draft a mission statement so you're 100% clear on your direction. I'll show you how to produce a SWOT analysis to understand your market.",
		},
		{
			question: "Module 2: Your Ideal Client",
			answer:
				"You'll identify your target market and what they like, want and need. Plus, you'll understand exactly what problem you solve for people and who you need to sell to.",
		},
		{
			question: "Module 3: Communication & Onboarding",
			answer: (
				<span>
					You'll learn how to talk to your ideal clients and where to find them.
					Plus, what to do when they become clients and how to create a smooth
					onboarding process.
					<br />
					Plus, we'll cover what makes a brilliant website and how you can make
					it easy for people to buy from you!
				</span>
			),
		},
		{
			question: "Module 4: Pricing & Packages",
			answer:
				"You'll get clear on your costs and I'll help you get over the fear of charging too much. We'll find the sweet spot pricing that helps you run a business that actually works for you and makes money.",
		},
		{
			question: "Module 5: Workflow, Systems & Processes",
			answer:
				"You'll create workflow systems that work for you, ensuring you never touch with your customers, forget to invoice them or remind them how brilliant you are!",
		},
		{
			question: "Module 6: Marketing Your Business",
			answer:
				"This is where it all comes together and you finally become visible to the world. You'll learn how to present yourself at networking events in way that makes you stand out from the crowd!",
		},
	];

	const breadcrumb = {
		"@context": "http://schema.org",
		"@type": "BreadcrumbList",
		itemListElement: [
			{
				"@type": "ListItem",
				position: 1,
				name: "Home",
				item: `${siteUrl}`,
			},
			{
				"@type": "ListItem",
				position: 2,
				name: "Business of Photography Course",
				item: `${siteUrl}/services/business-of-photography-course`,
			},
		],
	};

	const serviceSchema = {
		"@context": "https://schema.org/",
		"@type": "Product",
		"@id": `${siteUrl}${pageUrl}#business-of-photography-course`,
		image: `${siteUrl}${schemaImg}`,
		mpn: `${siteUrl}`,
		sku: `${siteUrl}${pageUrl}`,
		description:
			"Learn how to take stunning BESPOKE business images for your social media feeds.",
		logo: `${siteUrl}${logoImg}`,
		name: `${companyName}`,
		url: `${siteUrl}`,
		brand: {
			"@type": "Organization",
			logo: `${siteUrl}${logoImg}`,
			image: `${siteUrl}${schemaImg}`,
			name: `${companyName}`,
			url: `${siteUrl}`,
		},
		aggregateRating: {
			"@type": "AggregateRating",
			reviewCount: "24",
			ratingValue: "5",
			bestRating: "5",
			worstRating: "1",
		},
	};
	return (
		<Layout>
			<Helmet>
				<script type="application/ld+json">{JSON.stringify(breadcrumb)}</script>
			</Helmet>
			<Helmet>
				<script type="application/ld+json">
					{JSON.stringify(serviceSchema)}
				</script>
			</Helmet>
			<GatsbySeo
				title="The Bussiness Of Photography Course | Sarah Bennett Photography"
				description="Ready to turn your passion into your profession?"
				language="en"
				canonical="https://www.sarah-bennett.com/services/business-of-photography-course"
				openGraph={{
					url: "https://www.sarah-bennett.com/services/business-of-photography-course",
					title:
						"The Bussiness Of Photography Course | Sarah Bennett Commerical Photography",
					description: "Ready to turn your passion into your profession?",
					images: [
						{
							url: `${data.heroImg.publicURL}`,
							width: 800,
							height: 600,
							alt: "Business of Photography course",
						},
					],
				}}
			/>
			<ServicesHero
				headline={["The Bussiness Of", <br />, "Photography"]}
				subheadline="Ready to turn your passion into your profession?"
				img={data.heroImg.childImageSharp.gatsbyImageData}
				showBtn={true}
				url="/courses/business-of-photography-course"
				btnText="Take the course"
			/>
			<section>
				<Container>
					<Row>
						<Col className="text-center mt-5">
							<h3>
								I'm ready to teach you the 6-steps I took to successfully launch
								my professional photography business.{" "}
							</h3>
							<hr className="w-25 mx-auto" />
						</Col>
					</Row>
				</Container>
			</section>
			<PhotoRight
				heading="Time To Get Serious..."
				height="24rem"
				text={
					<span>
						Right now, you're a hobbyist. Perhaps you work weekends and take
						photos for family and friends.
						<br />
						But you're ready to get serious and turn your love of photography
						into a career.
						<br />
						You're committed to making your dream a reality.
						<br />
						You know this won't be a 9-5 job! It’s hard work but it’s worth it
						to have a flexible, profitable business doing what you love!
					</span>
				}
				img={data.bcImage1.childImageSharp.gatsbyImageData}
			/>
			<CtaBanner
				headline="You Just Need A Little Help Making It Happen!"
				btnText="Yes - Count Me In!"
				url="/courses/business-of-photography-course"
			/>
			<PhotoLeft
				img={data.bcImage2.childImageSharp.gatsbyImageData}
				heading="Because You've Got A Lot Of Questions:"
				height="28rem"
				text={
					<span>
						<ul className="course-list">
							<li>What genre should I choose to work in?</li>
							<li> How much should I charge?</li>
							<li>Where will I find customers?</li>
							<li>What should I call my business?</li>
							<li>What equipment will I need?</li>
							<li>How can I succeed with so much competition?</li>
						</ul>
						<span>And most pressing of all: </span>
						<br />
						<span className="fw-bold">
							Can I really make money as a photographer?
						</span>
					</span>
				}
			/>
			<CtaBanner
				headline="The Answer Is Yes You Can! And I'm Ready To Show You How..."
				btnText="Yes - Show Me How!"
				url="/courses/business-of-photography-course"
			/>
			<PhotoRight
				img={data.bcImage3.childImageSharp.gatsbyImageData}
				height="35rem"
				heading="Hi, I'm Sarah!"
				text={
					<span>
						I'm a professional commercial photographer, and your Business of
						Photography Coach.
						<br />
						In 2013, when my son Arthur arrived, my priorities changed. I no
						longer wanted to commute to London daily. I wanted autonomy,
						creativity and the flexibility to work around my family and friends.
						<br />
						Perhaps you can relate?
						<br />I understand how scary it can feel to take the leap and become
						a full time, professional photographer. That's why I created The
						Business of Photography Course. <br />
						Inside I'll teach you the same 6-step process I used when I started
						my fully paid, full time photography business.
					</span>
				}
			/>

			<PhotoLeft
				img={data.bcImage4.childImageSharp.gatsbyImageData}
				heading="All The Tools You Need To Build A Successful Photography Business"
				height="29rem"
				text={
					<span>
						Whatever genre you want to work in, the tools inside this self-paced
						course will guide you through the key decisions you need to make to
						ensure you build a sustainable, profitable photography business.
						<br />
						Plus your comprehensive accompanying workbook will keep you
						accountable as you execute your plans!
						<br />
						Follow the modules and do the work and you'll be ready to launch
						your photography business with confidence by the end of the course!
					</span>
				}
			/>
			<section>
				<Container>
					<Row>
						<Col className="text-center my-0 ">
							<h2>What's Covered On The Course?</h2>
							<hr className="w-25 mx-auto" />
						</Col>
					</Row>
				</Container>
			</section>
			<Faq faqs={faqs} hideHeader={true} />
			<section>
				<Container>
					<Row className="pb-5">
						<Col className="text-center">
							<h2>So, What Does All This Mean For You?</h2>
							<hr className="w-25 mx-auto" />
						</Col>
					</Row>
					<Row className="pb-6 my-auto">
						<Col className="ps-lg-6">
							<ul className="course-list-lg  ps-1 ">
								<li className="pb-3 ">
									A clear, strong brand identity that makes you the go-to for
									your ideal clients.
								</li>
								<li className="pb-3">
									Natural, easy communication that resonates with the right
									people.
								</li>
								<li className="pb-3">
									A career that barely feels like work because you're doing what
									you love.
								</li>
								<li className="pb-3">
									Painless, smooth workflows that prompt, support and save you
									time.
								</li>
								<li className="pb-3">
									Targetted, effective marketing strategies that bring business
									to you.
								</li>
								<li className="pb-3">
									Clarity on your niche, your audience, your pricing and your
									future.
								</li>
								<li className="pb-3">
									{" "}
									Pricing that's sustainable, profitable and makes you feel
									amazing.
								</li>
							</ul>
						</Col>
					</Row>
				</Container>
			</section>

			<CtaBanner
				headline="Ready To Learn, Follow And Implement My 6-Step Roadmap To Success?"
				btnText="Yes - I'm Ready!"
				url="/courses/business-of-photography-course"
			/>
		</Layout>
	);
};

export default BusinessCourse;
