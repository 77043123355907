import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Link from "../link";
import { GatsbyImage } from "gatsby-plugin-image";

const PhotoRight = ({ heading, text, img, cta, ctaUrl, height }) => {
	const newHeight = height || "";
	return (
		<section className="my-5 my-md-7 my-lg-10">
			<Container>
				<Row className="align-items-center">
					<Col xs={12} lg={6} className="order-1 order-lg-0">
						<h2 className="my-4 mb-md-0">{heading}</h2>
						<p>{text}</p>
						<Link to={ctaUrl}>
							<p className="text-primary fw-bold cta-link">{cta}</p>
						</Link>
					</Col>
					<Col xs={12} lg={6} className="order-0 order-lg-1">
						<GatsbyImage style={{ height: newHeight }} image={img} />
					</Col>
				</Row>
			</Container>
		</section>
	);
};

export default PhotoRight;
